// all js here
$(document).ready(function() {
    $('.js-login-page').bxSlider({
        auto: true,
        autoHover: true,
        pause: 3000,
        pager: false,
        controls: false 
    });
    $(".toggle-password").on('click', function() {
        $(this).toggleClass("fa-eye fa-eye-slash");
        var input = $('.toggle-password-target');
        if ("password" == input.attr("type")) {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }
    });
});
